import React from "react";
import Layout from "../components/layout"

export default function Loader(props) {

  return (
    <div style={{ overflow:'hidden',display: 'flex', marginTop: '200px', alignItems: 'center', justifyContent: 'center' }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
    </div>

  );
}

export function LayoutLoader(props) {
  return (
    <Layout showLoginButton={false}>
      <main className="container">
        <div className="debieBusinessInfo">
            <div className="row">
                <div className="col-sm-12">
                    <div className="dashboardWrap">
                        <div className="heading">
                            {/* <h1>businessName</h1> */}
                        </div>
                        <Loader/>
                    </div>
                </div>
            </div>
        </div>
    </main>
    </Layout>
  )
}
